<template>
  <div class="pa-3">
    <v-data-table
      :headers="Headers"
      :items="communities"
      class="elevation-1 tb px-2"
      :search="search"
    >
      <template v-slot:top>
        <v-row justify="end" no-gutters>
          <v-toolbar flat>
            <b>COMMUNITY INFORMATION</b>
          </v-toolbar>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-row>
      </template>
      <template #item.Beneficiaries="{ item }">
        <v-btn
          x-small
          download="Beneficiaries List"
          color="primary"
          :href="`/uploads/ekscda/${item.Beneficiaries}`"
          target="_blank"
          >Download Beneficiaries</v-btn
        >
      </template>

      <template #item.actions="{ item }">
        <v-btn
          :to="{ name: 'CommunityInfo', params: { editUser: { ...item } } }"
          icon
          x-small
          class="mr-2"
          title="Click to edit"
          color="black"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        <!-- <v-icon
          icon
          small
          @click="del(item)"
          title="Click to delete "
          color="error"
        >
          mdi-delete
        </v-icon> -->
      </template>
      
    </v-data-table>

    <vue-excel-xlsx
      class="mt-2 text-center"
      :data="communities"
      :columns="exceldatas"
      :filename="'Community Data'"
      :sheetname="'Community Sheets'"
    >
      <v-btn small rounded class="white--text" color="primary">
        Export to Excel</v-btn
      >
    </vue-excel-xlsx>

    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p>{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { communityData } from "@/excels";
import { apiClient } from "@/services";
import { snackMixin } from "@/mixins";
export default {
  name: "CommunityInfoTable",
  mixins: [snackMixin],
  data() {
    return {
      exceldatas: communityData,
      search: "",
      Headers: [
        { text: "Ref No", value: "CDPRef" },
        { text: "LGA", value: "LGA" },
        { text: "Community/Group Name", value: "CommunityName" },
        { text: "Senatorial District", value: "SenatorialDist" },
        { text: "Ward", value: "Ward" },
        { text: "Population", value: "Population" },
        { text: "No of Male", value: "CommunityMaleNo" },
        { text: "No of Female", value: "CommunityFemaleNo" },
        { text: "Social Group Elderly", value: "SocialGroupElder" },
        { text: "Social Group Men", value: "SocialGroupMen" },
        { text: "Social Group Women", value: "SocialGroupWomen" },
        { text: "Social Group Youth", value: "SocialGroupYouth" },
        { text: "Social Group Vulnerables", value: "SocialGroupVulnerable" },
        { text: "Total No of Beneficiaries", value: "noOfBeneficiaries" },
        { text: "Name of Beneficiaries", value: "Beneficiaries" },
        { text: "Created By", value: "created_by" },
        { text: "Modified By", value: "modified_by" },
        { text: "Actions", value: "actions" },
      ],
      communities: [],
    };
  },

  created() {
    apiClient
      .get("/community_info?u=2")
      .then((res) => {
        if (Array.isArray(res.data)) {
          this.communities = res.data;
        } else {
          this.displayMsg(res.data, "error");
        }
      })
      .catch((err) => this.displayMsg(err.message, "error"));
  },

  methods: {
    /* del(item) {
      const ok = confirm(`Are sure you want to delete ${item.CommunityRefNo}?`);
      if (ok) {
        this.displayMsg(`Deleting ${item.CommunityRefNo}, please wait...`);
        apiClient
          .delete(`/administrative?${item.id}`)
          .then((res) => {
            if (!res.data.ok) {
              this.displayMsg(
                `${item.CommunityRefNo} not successfully deleted`,
                "error"
              );
              return;
            } else {
              this.communities = this.communities.filter(
                (pt) => pt.id !== item.id
              );
              this.displayMsg(`Successfully deleted ${item.CommunityRefNo} `);
            }
          })
          .catch((err) => this.displayMsg(`${err.message}`, "error"));
      }
    }, */
  },
};
</script>
<style scoped>
.tb tr,
.tb th {
  border: 1px solid black;
}
</style>
